/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import '~primeicons/primeicons.css';
@import '~primeng/resources/primeng.min.css';
@import 'styles/fonts';
@import 'styles/mixins';
@import 'styles/theme';
@import 'styles/variables';

@import '@soctrip-common/icon/soctrip-icon.css';
body {
  width: 100%;
  background-color: #f2f4f7;
}
