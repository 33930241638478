@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: local('Inter-Light'), url('/assets/fonts/Inter/static/Inter-Light.ttf') format('opentype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: local('Inter-Regular'), url('/assets/fonts/Inter/static/Inter-Regular.ttf') format('opentype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: local('Inter-Medium'), url('/assets/fonts/Inter/static/Inter-Medium.ttf') format('opentype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: local('Inter-Semibold'), url('/assets/fonts/Inter/static/Inter-SemiBold.ttf') format('opentype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: local('Inter-ExtraBold'), url('/assets/fonts/Inter/static/Inter-ExtraBold.ttf') format('opentype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  src: local('Inter-ExtraBold'), url('/assets/fonts/Inter/static/Inter-ExtraBold.ttf') format('opentype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  src: local('Inter-Black'), url('/assets/fonts/Inter/static/Inter-Black.ttf') format('opentype');
}
